'use strict';

// 佐川もヤマトも箱サイズ表記は共通とする
// 佐川は120サイズに該当するタリフがなく100超140サイズは140サイズタリフが適用されるだけと考える
const boxSizeMap = {
  551: '60サイズ', // 廃止・過去の表記のために残す
  552: '60サイズ', // 廃止・過去の表記のために残す
  553: '60サイズ',
  N461: '60サイズ', // 廃止・過去の表記のために残す
  N501: '60サイズ',
  N801: '80サイズ', // 廃止・過去の表記のために残す
  802: '80サイズ',
  804: '80サイズ',
  601: '60サイズ',
  751: '80サイズ',
  N901: '100サイズ',
  903: '100サイズ',
  901: '100サイズ',
  1101: '120サイズ',
  1203: '120サイズ',
  904: '100サイズ',
  1202: '120サイズ', // 廃止・過去の表記のために残す
  1401: '140サイズ',
  1801: '180サイズ',
  1802: '180サイズ',
  'N461-v2': '60サイズ', // 廃止・過去の表記のために残す
  'N801-v2': '80サイズ', // 廃止・過去の表記のために残す
  '601-v2': '60サイズ', // 廃止・過去の表記のために残す
  '751-v2': '80サイズ', // 廃止・過去の表記のために残す
  'N901-v2': '100サイズ', // 廃止・過去の表記のために残す
  '903-v2': '100サイズ', // 廃止・過去の表記のために残す
  '901-v2': '100サイズ', // 廃止・過去の表記のために残す
  '1203-v2': '120サイズ', // 廃止・過去の表記のために残す
  '904-v2': '100サイズ', // 廃止・過去の表記のために残す
  1204: '120サイズ', // 廃止・過去の表記のために残す
  '1204-v2': '120サイズ', // 廃止・過去の表記のために残す
  '1401-v2': '140サイズ', // 廃止・過去の表記のために残す
  '1801-v2': '180サイズ', // 廃止・過去の表記のために残す
  '1802-v2': '180サイズ' // 廃止・過去の表記のために残す
};
module.exports = {
  YAMATO: boxSizeMap,
  SAGAWA: boxSizeMap
};
